import React from 'react';

import './LoginPage.css'; // 引入CSS样式文件

function LoginPage() {
  const handleLogin = () => {
    // 构建企业微信认证URL
    const CORPID = 'ww2409f5d21be737d8'; // 替换为您的企业ID
    const REDIRECT_URI = encodeURIComponent('https://tcloud.daweiclothing.com/callback'); // 您的回调URL
    const AGENTID = '1000007'; // 替换为您的应用ID
    const STATE = 'YOUR_STATE'; // 用于防CSRF攻击的随机字符串

    const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${CORPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_privateinfo&state=${STATE}&agentid=${AGENTID}#wechat_redirect`;

    // 重定向到企业微信认证URL
    window.location.href = authUrl;
  };

  return (
    <div className="login-container">
      <button className="login-button" onClick={handleLogin}>
        授予权限并查看账户信息
      </button>
    </div>
  );
}

export default LoginPage;