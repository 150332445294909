import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './CallbackPage.css'; // 引入CSS文件

function CallbackPage() {
    const location = useLocation();
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const hasFetched = useRef(false);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (!hasFetched.current) {
            hasFetched.current = true;
            setIsLoading(true); // 开始加载
            const queryParams = new URLSearchParams(location.search);
            const code = queryParams.get('code');
            if (code) {
                fetch(`/api/callback?code=${code}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            const text = await response.text();
                            throw new Error(text);
                        }
                    })
                    .then(data => {
                        setUserInfo(data);
                        setIsLoading(false); // 加载结束
                    })
                    .catch(error => {
                        console.error('Error fetching user info:', error);
                        setError(error.message);
                        setIsLoading(false); // 加载结束
                    });

            }
        }
    }, [location]);

    if (isLoading) {
        return <div className="callback-container">
            <div>正在查询中，请稍后...</div>
        </div>
    }

    return (
        <div className="callback-container">
            {error && <div className="error">错误，请联系管理员：{error}</div>}
            {userInfo && (
                <div className="user-info">
                    <h1>注册成功！</h1>
                    <p>以下是您的账号信息：</p>
                    <div className="info-field">
                        <span>登录用户名：</span><span>{userInfo.UserId}</span>
                    </div>
                    <div className="info-field">
                        <span>登录密码：</span><span>{userInfo.UserPassword}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CallbackPage;
